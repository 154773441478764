import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { UserProvider } from './infrastructure/UserProvider';
import { Toaster } from "react-hot-toast";

Amplify.configure(aws_exports);
ReactDOM.render(
  <UserProvider>
    <App />
    <Toaster />
  </UserProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
