import React, { useState } from 'react';

const RegistrationContext = React.createContext([{}, () => {}]);

const RegistrationProvider = (props) => {
  const [state, setState] = useState({
    'user': {}
  });

  return (
    <RegistrationContext.Provider value={[state, setState]}>
      {props.children}
    </RegistrationContext.Provider>
  );
};

export { RegistrationContext, RegistrationProvider };