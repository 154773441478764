import React, { Component } from 'react';
import './App.scss';
import BasePage, { ProtectRoute } from './pages/BasePage';
import { LoginProvider } from "./LoginContext";
import { RegistrationProvider } from "./RegistrationContext";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <LoginProvider>
        <RegistrationProvider>
          <ProtectRoute/>
        </RegistrationProvider>
        </LoginProvider>
      </React.Fragment>
    )
  }

}

export default App;
