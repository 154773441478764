/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrUpdateGeneralConfig = /* GraphQL */ `
  mutation CreateOrUpdateGeneralConfig($input: LambdaSettingInput) {
    createOrUpdateGeneralConfig(input: $input) {
      status
      message
    }
  }
`;
export const createMomReportCsv = /* GraphQL */ `
  mutation CreateMomReportCsv(
    $idType: String
    $fullname: String
    $dateOfAttendanceFrom: String
    $dateOfAttendanceTo: String
    $email: String
    $language: String
  ) {
    createMomReportCsv(
      idType: $idType
      fullname: $fullname
      dateOfAttendanceFrom: $dateOfAttendanceFrom
      dateOfAttendanceTo: $dateOfAttendanceTo
      email: $email
      language: $language
    )
  }
`;
export const createUserListCsv = /* GraphQL */ `
  mutation CreateUserListCsv(
    $fullname: String
    $email: String
    $mobilePhoneNumber: String
    $joinDateFrom: String
    $joinDateTo: String
    $paymentStatus: String
    $language: String
  ) {
    createUserListCsv(
      fullname: $fullname
      email: $email
      mobilePhoneNumber: $mobilePhoneNumber
      joinDateFrom: $joinDateFrom
      joinDateTo: $joinDateTo
      paymentStatus: $paymentStatus
      language: $language
    )
  }
`;
export const createUserEvaluationReportCsv = /* GraphQL */ `
  mutation CreateUserEvaluationReportCsv(
    $evaluation_questions: String
    $startDate: String
    $endDate: String
    $language: String
  ) {
    createUserEvaluationReportCsv(
      evaluation_questions: $evaluation_questions
      startDate: $startDate
      endDate: $endDate
      language: $language
    )
  }
`;
export const passCognitoUserDetatilsToLambda = /* GraphQL */ `
  mutation PassCognitoUserDetatilsToLambda(
    $username: String
    $groupName: String
  ) {
    passCognitoUserDetatilsToLambda(username: $username, groupName: $groupName)
  }
`;
export const loginWithOtp = /* GraphQL */ `
  mutation LoginWithOtp(
    $mobilePhoneNumber: String
    $email: String
    $loginMode: String
  ) {
    loginWithOtp(
      mobilePhoneNumber: $mobilePhoneNumber
      email: $email
      loginMode: $loginMode
    ) {
      status
      message
    }
  }
`;
export const otpVerification = /* GraphQL */ `
  mutation OtpVerification(
    $mobilePhoneNumber: String
    $otp: String
    $email: String
    $loginMode: String
  ) {
    OtpVerification(
      mobilePhoneNumber: $mobilePhoneNumber
      otp: $otp
      email: $email
      loginMode: $loginMode
    ) {
      status
      message
      user {
        userid
        skipSingPass
        firstTimer
      }
    }
  }
`;
export const recordUserProgress = /* GraphQL */ `
  mutation RecordUserProgress(
    $userID: String
    $moduleID: String
    $courseID: String
  ) {
    recordUserProgress(
      userID: $userID
      moduleID: $moduleID
      courseID: $courseID
    )
  }
`;
export const checkCourseCompletion = /* GraphQL */ `
  mutation CheckCourseCompletion($userid: String) {
    checkCourseCompletion(userid: $userid)
  }
`;
export const createUserLambda = /* GraphQL */ `
  mutation CreateUserLambda($input: LambdaUserInput) {
    createUserLambda(input: $input) {
      status
      message
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        validityDate
        hasCompletedAllCourse
        emailMarketingOption
        smsMarketingOption
        createdAt
        modifiedAt
        previousEmploymentAgency
      }
    }
  }
`;
export const updateUserLambda = /* GraphQL */ `
  mutation UpdateUserLambda(
    $input: LambdaUserInput
    $forceDateUpdate: Boolean
  ) {
    updateUserLambda(input: $input, forceDateUpdate: $forceDateUpdate) {
      id
      identificationNumber
      identificationType
      passportNumber
      fullname
      nationality
      mobilePhoneNumber
      email
      dob
      dateOfAttendance
      language
      mode
      skipSingPass
      firstTimer
      validityDate
      emailMarketingOption
      smsMarketingOption
      hasCompletedAllCourse
      createdAt
      modifiedAt
      previousEmploymentAgency
    }
  }
`;
export const updateEvaluationAnswer = /* GraphQL */ `
  mutation UpdateEvaluationAnswer(
    $userid: String
    $answers: [LambdaEvaluationAnswerInput]
  ) {
    updateEvaluationAnswer(userid: $userid, answers: $answers) {
      userid
      answers {
        id
        questionSectionID
        questionID
        answerID
        answerValue
        createdAt
        modifiedAt
      }
    }
  }
`;
export const updateUserQuizAnswerLambda = /* GraphQL */ `
  mutation UpdateUserQuizAnswerLambda(
    $userID: String
    $courseSectionID: String
    $questionID: String
    $answers: [UserQuizAnswerInput]
  ) {
    updateUserQuizAnswerLambda(
      userID: $userID
      courseSectionID: $courseSectionID
      questionID: $questionID
      answers: $answers
    ) {
      userID
      courseSectionID
      items {
        id
        questionID
        subQuestionIndex
        answerID
        createdAt
        modifiedAt
      }
    }
  }
`;
export const updateFirstTimeStatus = /* GraphQL */ `
  mutation UpdateFirstTimeStatus($input: LambdaUserInput) {
    updateFirstTimeStatus(input: $input)
  }
`;
export const signupWithOtp = /* GraphQL */ `
  mutation SignupWithOtp(
    $mobilePhoneNumber: String
    $email: String
    $signUpMode: String
  ) {
    signupWithOtp(
      mobilePhoneNumber: $mobilePhoneNumber
      email: $email
      signUpMode: $signUpMode
    ) {
      status
      message
      error
    }
  }
`;
export const signupOtpVerification = /* GraphQL */ `
  mutation SignupOtpVerification(
    $mobilePhoneNumber: String
    $otp: String
    $email: String
    $signUpMode: String
  ) {
    signupOtpVerification(
      mobilePhoneNumber: $mobilePhoneNumber
      otp: $otp
      email: $email
      signUpMode: $signUpMode
    ) {
      status
      message
    }
  }
`;
export const getCourseStatus = /* GraphQL */ `
  mutation GetCourseStatus($courseID: String, $userID: String) {
    getCourseStatus(courseID: $courseID, userID: $userID)
  }
`;
export const deleteAnnouncementLambda = /* GraphQL */ `
  mutation DeleteAnnouncementLambda($id: String) {
    deleteAnnouncementLambda(id: $id)
  }
`;
export const checkUserInput = /* GraphQL */ `
  mutation CheckUserInput($mobilePhoneNumber: String) {
    checkUserInput(mobilePhoneNumber: $mobilePhoneNumber)
  }
`;
export const sendCourseCompletionDocument = /* GraphQL */ `
  mutation SendCourseCompletionDocument($userid: String, $email: String) {
    sendCourseCompletionDocument(userid: $userid, email: $email)
  }
`;
export const sendReceipt = /* GraphQL */ `
  mutation SendReceipt($paymentid: String, $email: String) {
    sendReceipt(paymentid: $paymentid, email: $email)
  }
`;
export const updateUserNotificationSubscription = /* GraphQL */ `
  mutation UpdateUserNotificationSubscription(
    $userID: String
    $email: String
    $emailMarketingOption: Boolean
    $smsMarketingOption: Boolean
  ) {
    updateUserNotificationSubscription(
      userID: $userID
      email: $email
      emailMarketingOption: $emailMarketingOption
      smsMarketingOption: $smsMarketingOption
    )
  }
`;
export const createPaymentTransactionListCsv = /* GraphQL */ `
  mutation CreatePaymentTransactionListCsv(
    $fullname: String
    $transactionID: String
    $transactionDateFrom: String
    $transactionDateTo: String
    $status: String
    $identificationNumber: String
    $language: String
  ) {
    createPaymentTransactionListCsv(
      fullname: $fullname
      transactionID: $transactionID
      transactionDateFrom: $transactionDateFrom
      transactionDateTo: $transactionDateTo
      status: $status
      identificationNumber: $identificationNumber
      language: $language
    )
  }
`;
export const updateUserCourseComplete = /* GraphQL */ `
  mutation UpdateUserCourseComplete($userid: String) {
    updateUserCourseComplete(userid: $userid)
  }
`;
export const checkIdentificationDuplication = /* GraphQL */ `
  mutation CheckIdentificationDuplication($identificationNumber: String) {
    checkIdentificationDuplication(identificationNumber: $identificationNumber)
  }
`;
export const createCourseContentExportListCsv = /* GraphQL */ `
  mutation CreateCourseContentExportListCsv(
    $fileName: String
    $email: String
    $status: String
    $expiryDateFrom: String
    $expiryDateTo: String
  ) {
    createCourseContentExportListCsv(
      fileName: $fileName
      email: $email
      status: $status
      expiryDateFrom: $expiryDateFrom
      expiryDateTo: $expiryDateTo
    )
  }
`;
export const createCourseContentExportZip = /* GraphQL */ `
  mutation CreateCourseContentExportZip($email: String, $id: String) {
    createCourseContentExportZip(email: $email, id: $id)
  }
`;
export const updatePaymentTransactionRecord = /* GraphQL */ `
  mutation UpdatePaymentTransactionRecord(
    $paymentTransactionID: String
    $remarks: String
    $paymentStatus: String
  ) {
    updatePaymentTransactionRecord(
      paymentTransactionID: $paymentTransactionID
      remarks: $remarks
      paymentStatus: $paymentStatus
    )
  }
`;
export const generateScormUploadLink = /* GraphQL */ `
  mutation GenerateScormUploadLink {
    generateScormUploadLink
  }
`;
export const updatePaymentStatus = /* GraphQL */ `
  mutation UpdatePaymentStatus($userID: String) {
    updatePaymentStatus(userID: $userID) {
      status
      message
    }
  }
`;
export const checkPaymentStatus = /* GraphQL */ `
  mutation CheckPaymentStatus($userID: String) {
    checkPaymentStatus(userID: $userID) {
      paymentStatus
      transactionID
    }
  }
`;
export const createPaymentTransactionRecord = /* GraphQL */ `
  mutation CreatePaymentTransactionRecord(
    $input: createPaymentTransactionInput
  ) {
    createPaymentTransactionRecord(input: $input)
  }
`;
export const createCourseModule = /* GraphQL */ `
  mutation CreateCourseModule($input: CreateCourseModuleInput) {
    createCourseModule(input: $input) {
      id
      sequence
      title
      slug
      img
      createdAt
      modifiedAt
      course {
        items {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCourseModule = /* GraphQL */ `
  mutation UpdateCourseModule($input: UpdateCourseModuleInput) {
    updateCourseModule(input: $input) {
      id
      sequence
      title
      slug
      img
      createdAt
      modifiedAt
      course {
        items {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCourseModule = /* GraphQL */ `
  mutation DeleteCourseModule($input: DeleteCourseModuleInput) {
    deleteCourseModule(input: $input) {
      id
      sequence
      title
      slug
      img
      createdAt
      modifiedAt
      course {
        items {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse($input: CreateCourseInput) {
    createCourse(input: $input) {
      id
      sequence
      title
      slug
      name
      type
      backgroundImg
      createdAt
      modifiedAt
      module {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        items {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse($input: UpdateCourseInput) {
    updateCourse(input: $input) {
      id
      sequence
      title
      slug
      name
      type
      backgroundImg
      createdAt
      modifiedAt
      module {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        items {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse($input: DeleteCourseInput) {
    deleteCourse(input: $input) {
      id
      sequence
      title
      slug
      name
      type
      backgroundImg
      createdAt
      modifiedAt
      module {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        items {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCourseSection = /* GraphQL */ `
  mutation CreateCourseSection($input: CreateCourseSectionInput) {
    createCourseSection(input: $input) {
      id
      sequence
      title
      text
      type
      backgroundImg
      createdAt
      modifiedAt
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseContent {
        items {
          id
          sequence
          title
          text
          mediaURL
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quiz {
        items {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCourseSection = /* GraphQL */ `
  mutation UpdateCourseSection($input: UpdateCourseSectionInput) {
    updateCourseSection(input: $input) {
      id
      sequence
      title
      text
      type
      backgroundImg
      createdAt
      modifiedAt
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseContent {
        items {
          id
          sequence
          title
          text
          mediaURL
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quiz {
        items {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCourseSection = /* GraphQL */ `
  mutation DeleteCourseSection($input: DeleteCourseSectionInput) {
    deleteCourseSection(input: $input) {
      id
      sequence
      title
      text
      type
      backgroundImg
      createdAt
      modifiedAt
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseContent {
        items {
          id
          sequence
          title
          text
          mediaURL
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quiz {
        items {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCourseContent = /* GraphQL */ `
  mutation CreateCourseContent($input: CreateCourseContentInput) {
    createCourseContent(input: $input) {
      id
      sequence
      title
      text
      mediaURL
      createdAt
      modifiedAt
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCourseContent = /* GraphQL */ `
  mutation UpdateCourseContent($input: UpdateCourseContentInput) {
    updateCourseContent(input: $input) {
      id
      sequence
      title
      text
      mediaURL
      createdAt
      modifiedAt
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCourseContent = /* GraphQL */ `
  mutation DeleteCourseContent($input: DeleteCourseContentInput) {
    deleteCourseContent(input: $input) {
      id
      sequence
      title
      text
      mediaURL
      createdAt
      modifiedAt
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCourseResource = /* GraphQL */ `
  mutation CreateCourseResource($input: CreateCourseResourceInput) {
    createCourseResource(input: $input) {
      id
      language
      title
      text
      media
      explanation
      courseContent {
        id
        sequence
        title
        text
        mediaURL
        createdAt
        modifiedAt
        courseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseModule {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizAnswerOption {
        id
        sequence
        text
        isCorrect
        explanation
        subQuestionIndex
        createdAt
        modifiedAt
        quizQuestion {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseResource = /* GraphQL */ `
  mutation UpdateCourseResource($input: UpdateCourseResourceInput) {
    updateCourseResource(input: $input) {
      id
      language
      title
      text
      media
      explanation
      courseContent {
        id
        sequence
        title
        text
        mediaURL
        createdAt
        modifiedAt
        courseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseModule {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizAnswerOption {
        id
        sequence
        text
        isCorrect
        explanation
        subQuestionIndex
        createdAt
        modifiedAt
        quizQuestion {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseResource = /* GraphQL */ `
  mutation DeleteCourseResource($input: DeleteCourseResourceInput) {
    deleteCourseResource(input: $input) {
      id
      language
      title
      text
      media
      explanation
      courseContent {
        id
        sequence
        title
        text
        mediaURL
        createdAt
        modifiedAt
        courseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseModule {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizAnswerOption {
        id
        sequence
        text
        isCorrect
        explanation
        subQuestionIndex
        createdAt
        modifiedAt
        quizQuestion {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserProgress = /* GraphQL */ `
  mutation CreateUserProgress($input: CreateUserProgressInput) {
    createUserProgress(input: $input) {
      id
      userID
      courseID
      moduleID
      progressPercentage
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const updateUserProgress = /* GraphQL */ `
  mutation UpdateUserProgress($input: UpdateUserProgressInput) {
    updateUserProgress(input: $input) {
      id
      userID
      courseID
      moduleID
      progressPercentage
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const deleteUserProgress = /* GraphQL */ `
  mutation DeleteUserProgress($input: DeleteUserProgressInput) {
    deleteUserProgress(input: $input) {
      id
      userID
      courseID
      moduleID
      progressPercentage
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz($input: CreateQuizInput) {
    createQuiz(input: $input) {
      id
      type
      title
      description
      createdAt
      modifiedAt
      courseSectionID
      quizCourseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      question {
        items {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz($input: UpdateQuizInput) {
    updateQuiz(input: $input) {
      id
      type
      title
      description
      createdAt
      modifiedAt
      courseSectionID
      quizCourseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      question {
        items {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz($input: DeleteQuizInput) {
    deleteQuiz(input: $input) {
      id
      type
      title
      description
      createdAt
      modifiedAt
      courseSectionID
      quizCourseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      question {
        items {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createQuizQuestion = /* GraphQL */ `
  mutation CreateQuizQuestion($input: CreateQuizQuestionInput) {
    createQuizQuestion(input: $input) {
      id
      sequence
      title
      explanation
      createdAt
      modifiedAt
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      answerOptions {
        items {
          id
          sequence
          text
          isCorrect
          explanation
          subQuestionIndex
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateQuizQuestion = /* GraphQL */ `
  mutation UpdateQuizQuestion($input: UpdateQuizQuestionInput) {
    updateQuizQuestion(input: $input) {
      id
      sequence
      title
      explanation
      createdAt
      modifiedAt
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      answerOptions {
        items {
          id
          sequence
          text
          isCorrect
          explanation
          subQuestionIndex
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteQuizQuestion = /* GraphQL */ `
  mutation DeleteQuizQuestion($input: DeleteQuizQuestionInput) {
    deleteQuizQuestion(input: $input) {
      id
      sequence
      title
      explanation
      createdAt
      modifiedAt
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      answerOptions {
        items {
          id
          sequence
          text
          isCorrect
          explanation
          subQuestionIndex
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createQuizAnswerOption = /* GraphQL */ `
  mutation CreateQuizAnswerOption($input: CreateQuizAnswerOptionInput) {
    createQuizAnswerOption(input: $input) {
      id
      sequence
      text
      isCorrect
      explanation
      subQuestionIndex
      createdAt
      modifiedAt
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateQuizAnswerOption = /* GraphQL */ `
  mutation UpdateQuizAnswerOption($input: UpdateQuizAnswerOptionInput) {
    updateQuizAnswerOption(input: $input) {
      id
      sequence
      text
      isCorrect
      explanation
      subQuestionIndex
      createdAt
      modifiedAt
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteQuizAnswerOption = /* GraphQL */ `
  mutation DeleteQuizAnswerOption($input: DeleteQuizAnswerOptionInput) {
    deleteQuizAnswerOption(input: $input) {
      id
      sequence
      text
      isCorrect
      explanation
      subQuestionIndex
      createdAt
      modifiedAt
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createEvaluationQuestionSection = /* GraphQL */ `
  mutation CreateEvaluationQuestionSection(
    $input: CreateEvaluationQuestionSectionInput
  ) {
    createEvaluationQuestionSection(input: $input) {
      id
      sequence
      title
      type
      createdAt
      modifiedAt
      question {
        items {
          id
          sequence
          title
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      ratingScale {
        items {
          id
          sequence
          text
          value
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateEvaluationQuestionSection = /* GraphQL */ `
  mutation UpdateEvaluationQuestionSection(
    $input: UpdateEvaluationQuestionSectionInput
  ) {
    updateEvaluationQuestionSection(input: $input) {
      id
      sequence
      title
      type
      createdAt
      modifiedAt
      question {
        items {
          id
          sequence
          title
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      ratingScale {
        items {
          id
          sequence
          text
          value
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteEvaluationQuestionSection = /* GraphQL */ `
  mutation DeleteEvaluationQuestionSection(
    $input: DeleteEvaluationQuestionSectionInput
  ) {
    deleteEvaluationQuestionSection(input: $input) {
      id
      sequence
      title
      type
      createdAt
      modifiedAt
      question {
        items {
          id
          sequence
          title
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      ratingScale {
        items {
          id
          sequence
          text
          value
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createEvaluationQuestion = /* GraphQL */ `
  mutation CreateEvaluationQuestion($input: CreateEvaluationQuestionInput) {
    createEvaluationQuestion(input: $input) {
      id
      sequence
      title
      createdAt
      modifiedAt
      section {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateEvaluationQuestion = /* GraphQL */ `
  mutation UpdateEvaluationQuestion($input: UpdateEvaluationQuestionInput) {
    updateEvaluationQuestion(input: $input) {
      id
      sequence
      title
      createdAt
      modifiedAt
      section {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteEvaluationQuestion = /* GraphQL */ `
  mutation DeleteEvaluationQuestion($input: DeleteEvaluationQuestionInput) {
    deleteEvaluationQuestion(input: $input) {
      id
      sequence
      title
      createdAt
      modifiedAt
      section {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createCourseContentExport = /* GraphQL */ `
  mutation CreateCourseContentExport($input: CreateCourseContentExportInput) {
    createCourseContentExport(input: $input) {
      id
      fileName
      expiryDate
      email
      status
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const updateCourseContentExport = /* GraphQL */ `
  mutation UpdateCourseContentExport($input: UpdateCourseContentExportInput) {
    updateCourseContentExport(input: $input) {
      id
      fileName
      expiryDate
      email
      status
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const deleteCourseContentExport = /* GraphQL */ `
  mutation DeleteCourseContentExport($input: DeleteCourseContentExportInput) {
    deleteCourseContentExport(input: $input) {
      id
      fileName
      expiryDate
      email
      status
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const createEvaluationRatingScale = /* GraphQL */ `
  mutation CreateEvaluationRatingScale(
    $input: CreateEvaluationRatingScaleInput
  ) {
    createEvaluationRatingScale(input: $input) {
      id
      sequence
      text
      value
      createdAt
      modifiedAt
      evaluationQuestionSection {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateEvaluationRatingScale = /* GraphQL */ `
  mutation UpdateEvaluationRatingScale(
    $input: UpdateEvaluationRatingScaleInput
  ) {
    updateEvaluationRatingScale(input: $input) {
      id
      sequence
      text
      value
      createdAt
      modifiedAt
      evaluationQuestionSection {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteEvaluationRatingScale = /* GraphQL */ `
  mutation DeleteEvaluationRatingScale(
    $input: DeleteEvaluationRatingScaleInput
  ) {
    deleteEvaluationRatingScale(input: $input) {
      id
      sequence
      text
      value
      createdAt
      modifiedAt
      evaluationQuestionSection {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createUserQuizAnswer = /* GraphQL */ `
  mutation CreateUserQuizAnswer($input: CreateUserQuizAnswerInput) {
    createUserQuizAnswer(input: $input) {
      id
      questionID
      subQuestionIndex
      answerID
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const updateUserQuizAnswer = /* GraphQL */ `
  mutation UpdateUserQuizAnswer($input: UpdateUserQuizAnswerInput) {
    updateUserQuizAnswer(input: $input) {
      id
      questionID
      subQuestionIndex
      answerID
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserQuizAnswer = /* GraphQL */ `
  mutation DeleteUserQuizAnswer($input: DeleteUserQuizAnswerInput) {
    deleteUserQuizAnswer(input: $input) {
      id
      questionID
      subQuestionIndex
      answerID
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      id
      identificationNumber
      identificationType
      passportNumber
      fullname
      nationality
      mobilePhoneNumber
      email
      dob
      dateOfAttendance
      language
      mode
      skipSingPass
      firstTimer
      paymentStatus
      disallowUserSkippingCourse
      validityDate
      emailMarketingOption
      smsMarketingOption
      hasCompletedAllCourse
      hasRefunded
      previousEmploymentAgency
      createdAt
      modifiedAt
      updatedAt
      userQuizAnswers {
        items {
          id
          questionID
          subQuestionIndex
          answerID
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userEvaluationAnswers {
        items {
          id
          questionSectionID
          questionID
          answerID
          answerValue
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userPaymentTransactions {
        items {
          id
          transactionID
          referenceNo
          status
          chequeNumber
          bankName
          eNetsTransactionID
          creditCardNumber
          paymentMethod
          modeOfDelivery
          language
          amount
          userID
          fullname
          identificationNumber
          identificationType
          createdAt
          modifiedAt
          remarks
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
      identificationNumber
      identificationType
      passportNumber
      fullname
      nationality
      mobilePhoneNumber
      email
      dob
      dateOfAttendance
      language
      mode
      skipSingPass
      firstTimer
      paymentStatus
      disallowUserSkippingCourse
      validityDate
      emailMarketingOption
      smsMarketingOption
      hasCompletedAllCourse
      hasRefunded
      previousEmploymentAgency
      createdAt
      modifiedAt
      updatedAt
      userQuizAnswers {
        items {
          id
          questionID
          subQuestionIndex
          answerID
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userEvaluationAnswers {
        items {
          id
          questionSectionID
          questionID
          answerID
          answerValue
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userPaymentTransactions {
        items {
          id
          transactionID
          referenceNo
          status
          chequeNumber
          bankName
          eNetsTransactionID
          creditCardNumber
          paymentMethod
          modeOfDelivery
          language
          amount
          userID
          fullname
          identificationNumber
          identificationType
          createdAt
          modifiedAt
          remarks
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput) {
    deleteUser(input: $input) {
      id
      identificationNumber
      identificationType
      passportNumber
      fullname
      nationality
      mobilePhoneNumber
      email
      dob
      dateOfAttendance
      language
      mode
      skipSingPass
      firstTimer
      paymentStatus
      disallowUserSkippingCourse
      validityDate
      emailMarketingOption
      smsMarketingOption
      hasCompletedAllCourse
      hasRefunded
      previousEmploymentAgency
      createdAt
      modifiedAt
      updatedAt
      userQuizAnswers {
        items {
          id
          questionID
          subQuestionIndex
          answerID
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userEvaluationAnswers {
        items {
          id
          questionSectionID
          questionID
          answerID
          answerValue
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userPaymentTransactions {
        items {
          id
          transactionID
          referenceNo
          status
          chequeNumber
          bankName
          eNetsTransactionID
          creditCardNumber
          paymentMethod
          modeOfDelivery
          language
          amount
          userID
          fullname
          identificationNumber
          identificationType
          createdAt
          modifiedAt
          remarks
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPaymentLog = /* GraphQL */ `
  mutation CreatePaymentLog($input: CreatePaymentLogInput) {
    createPaymentLog(input: $input) {
      id
      tranID
      txnStatus
      txnSignature
      txnSignature2
      tranDate
      merchantTranID
      responseCode
      responseDesc
      customerID
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const updatePaymentLog = /* GraphQL */ `
  mutation UpdatePaymentLog($input: UpdatePaymentLogInput) {
    updatePaymentLog(input: $input) {
      id
      tranID
      txnStatus
      txnSignature
      txnSignature2
      tranDate
      merchantTranID
      responseCode
      responseDesc
      customerID
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const deletePaymentLog = /* GraphQL */ `
  mutation DeletePaymentLog($input: DeletePaymentLogInput) {
    deletePaymentLog(input: $input) {
      id
      tranID
      txnStatus
      txnSignature
      txnSignature2
      tranDate
      merchantTranID
      responseCode
      responseDesc
      customerID
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const createUserEvaluationAnswer = /* GraphQL */ `
  mutation CreateUserEvaluationAnswer($input: CreateUserEvaluationAnswerInput) {
    createUserEvaluationAnswer(input: $input) {
      id
      questionSectionID
      questionID
      answerID
      answerValue
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const updateUserEvaluationAnswer = /* GraphQL */ `
  mutation UpdateUserEvaluationAnswer($input: UpdateUserEvaluationAnswerInput) {
    updateUserEvaluationAnswer(input: $input) {
      id
      questionSectionID
      questionID
      answerID
      answerValue
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserEvaluationAnswer = /* GraphQL */ `
  mutation DeleteUserEvaluationAnswer($input: DeleteUserEvaluationAnswerInput) {
    deleteUserEvaluationAnswer(input: $input) {
      id
      questionSectionID
      questionID
      answerID
      answerValue
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement($input: CreateAnnouncementInput) {
    createAnnouncement(input: $input) {
      id
      title
      text
      startDate
      endDate
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement($input: UpdateAnnouncementInput) {
    updateAnnouncement(input: $input) {
      id
      title
      text
      startDate
      endDate
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement($input: DeleteAnnouncementInput) {
    deleteAnnouncement(input: $input) {
      id
      title
      text
      startDate
      endDate
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const createPaymentTransaction = /* GraphQL */ `
  mutation CreatePaymentTransaction($input: CreatePaymentTransactionInput) {
    createPaymentTransaction(input: $input) {
      id
      transactionID
      referenceNo
      status
      chequeNumber
      bankName
      eNetsTransactionID
      creditCardNumber
      paymentMethod
      modeOfDelivery
      language
      amount
      userID
      fullname
      identificationNumber
      identificationType
      createdAt
      modifiedAt
      remarks
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const updatePaymentTransaction = /* GraphQL */ `
  mutation UpdatePaymentTransaction($input: UpdatePaymentTransactionInput) {
    updatePaymentTransaction(input: $input) {
      id
      transactionID
      referenceNo
      status
      chequeNumber
      bankName
      eNetsTransactionID
      creditCardNumber
      paymentMethod
      modeOfDelivery
      language
      amount
      userID
      fullname
      identificationNumber
      identificationType
      createdAt
      modifiedAt
      remarks
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const deletePaymentTransaction = /* GraphQL */ `
  mutation DeletePaymentTransaction($input: DeletePaymentTransactionInput) {
    deletePaymentTransaction(input: $input) {
      id
      transactionID
      referenceNo
      status
      chequeNumber
      bankName
      eNetsTransactionID
      creditCardNumber
      paymentMethod
      modeOfDelivery
      language
      amount
      userID
      fullname
      identificationNumber
      identificationType
      createdAt
      modifiedAt
      remarks
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
