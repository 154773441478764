import React, { useEffect, useState } from 'react'
import { Container } from '@material-ui/core';
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import NavBarDropDown from '../../components/navbar/NavBarDropDown';
import NavBarMobileMenu from '../../components/navbar/NavBarMobileMenu';
import { Auth } from "aws-amplify";
import './NavBar.scss';
import { getUserAuth, removeUserCookies } from '../../infrastructure/UserAuthentication';

function NavBar(props) {
  const [isAdminPortal, setIsAdminPortal] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(null);

  useEffect(() => {
    if (props.location.pathname.indexOf('/admin/') > -1) {
      setIsAdminPortal(true);
    }
    else {
      setIsAdminPortal(false);
    }

    async function getAdminSession() {
      try {
        const session = await Auth.currentSession();
        setIsAdminAuthenticated(session !== null && session !== undefined);
      }
      catch { }
    }
    getAdminSession();

    async function getUserSession() {
      let currentUserAuth = await getUserAuth();
      if (currentUserAuth) {
        setIsAuthenticated(currentUserAuth.userIsAuthorized);
      }
    }
    getUserSession();
  }, [props.location, props.refresh]);

  async function handleAdminLogOut() {
    try {
      await Auth.signOut();
      setIsAdminAuthenticated(false);
      props.history.push(process.env.REACT_APP_HOMEPAGE_URL);
    } catch (error) {
    }
  }

  async function handleLogOut() {
    try {
      removeUserCookies();
      setIsAuthenticated(false);
      props.history.push(process.env.REACT_APP_HOMEPAGE_URL);
    } catch (error) {
    }
  }

  return (
    <div className="navbar-wrapper">
      <AppBar position="static" color="default">
        <Container maxWidth="lg">
          <Toolbar className="navbar__toolbar">
            <div className="navbar__title">
              <Link to={process.env.REACT_APP_HOMEPAGE_URL}>
                <img src="/assets/img/eop-logo.png" alt="" />
              </Link>
              {/* <span className="title-text">GMC EOP</span> */}
            </div>
            <div className="navbar__menu-button" >
              <ul className="navbar__menu">
                <li>
                  <Link to={process.env.REACT_APP_HOMEPAGE_URL}>Homepage</Link>
                </li>
                <li>
                  <a href="http://www.gmcs.com.sg/" target="_blank" rel="noopener noreferrer">About GMC</a>
                </li>
                <li>
                  <a href="http://www.gmcs.com.sg/courses/" target="_blank" rel="noopener noreferrer">GMC Courses</a>
                </li>
              </ul>
              {isAdminPortal === false ?
                isAuthenticated ?
                  <React.Fragment>
                    {props.location.pathname.indexOf('/course/') < 0 ? <Link to="/course/summary" className="navbar__login-btn">Access Courses</Link> : ''}
                    <Button onClick={handleLogOut} className="navbar__login-btn">Log Out</Button>
                  </React.Fragment> :
                  <React.Fragment>
                    <Link to="/registration/agreement" className="navbar__login-btn">Enrol in EOP</Link>
                    <Link to="/login" className="navbar__login-btn">Log In</Link>
                    {/* <Link to="#" className="navbar__login-btn">Enrol to EOP</Link>
                    <Link to="#" className="navbar__login-btn">Log In</Link> */}
                  </React.Fragment>
                :
                isAdminAuthenticated ?
                  <React.Fragment>
                    <Button onClick={handleAdminLogOut} className="navbar__login-btn">Log Out</Button>
                  </React.Fragment> :
                  <React.Fragment>
                    <Link to="/admin/login" className="navbar__login-btn">Log In</Link>
                  </React.Fragment>
              }
            </div>
            <NavBarMobileMenu></NavBarMobileMenu>
          </Toolbar>
        </Container>
      </AppBar>
    </div >
  )
}
export default withRouter(NavBar);
