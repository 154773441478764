import { useState, useEffect, useCallback } from "react";
/*
    Usage:

    // stateSchema to state the schema, 'value' is the input value, and 'error' is the error message of the input if there is any
    const stateSchema = {
        fname: { value: '', error: '' },
        email: { value: '', error: '' },
        lname: { value: '', error: '' },
        tags: { value: '', error: '' },
    };

    // validationStateSchema is to state the scheme validation criterias, requiredErrorMsg will be use as error message for required
    const validationStateSchema = {
        fname: {
            required: true,
            validator: {
                regEx: /^[a-zA-Z]+$/,
                error: 'Invalid first name format.',
            },
        },
        email: {
            required: true,
            requiredErrorMsg: 'This field is required',
            validator: {
                regEx: /^(,?\w{3,})+$/,
                error: 'Invalid tag format.',
            },
        },
    };
*/

export default function useForm(
  stateSchema,
  validationSchema = {},
  callback,
  defaultShowErrorOnBlur = false
) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [showErrorOnBlur, setShowErrorOnBlur] = useState(
    defaultShowErrorOnBlur
  );

  useEffect(() => {
    setDisable(true);
  }, []);

  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
    }
  }, [state, isDirty]);

  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      const isInputFieldRequired = validationSchema[key].required;
      const stateValue = state[key].value; // state value
      const stateError = state[key].hasError; // state error

      return (isInputFieldRequired && !stateValue) || stateError;
    });

    return hasErrorInState;
  }, [state, validationSchema]);

  const handleOnClear = useCallback(() => {
    const value = "";
    const error = "";
    const hasError = false;
    setIsDirty(false);
    setState((prevState) => ({
      ...prevState,
      email: { value, error, hasError },
    }));
  });

  const handleOnChange = useCallback(
    (event) => {
      setIsDirty(true);
      const name = event.target.name;
      const value = event.target.value
        ? event.target.value
        : event.target.checked === true
        ? true
        : event.target.value;
      let error = "";
      let hasError = false;
      if (validationSchema[name].required) {
        if (!value) {
          hasError = true;
          if (showErrorOnBlur !== true) {
            if (validationSchema[name].requiredErrorMsg) {
              error = validationSchema[name].requiredErrorMsg;
            } else {
              error = "This is required field.";
            }
          }
        }
      }

      if (
        validationSchema[name].validator != null &&
        typeof validationSchema[name].validator === "object"
      ) {
        if (value && !validationSchema[name].validator.regEx.test(value)) {
          hasError = true;

          if (showErrorOnBlur !== true) {
            error = validationSchema[name].validator.error;
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        [name]: { value, error, hasError },
      }));
    },
    [validationSchema]
  );

  const handleOnBlur = useCallback(
    (event) => {
      setIsDirty(true);
      const name = event.target.name;
      const value = event.target.value
        ? event.target.value
        : event.target.checked === true
        ? true
        : event.target.value;
      let error = "";
      if (validationSchema[name].required) {
        if (!value) {
          if (validationSchema[name].requiredErrorMsg) {
            error = validationSchema[name].requiredErrorMsg;
          } else {
            error = "This is required field.";
          }
        }
      }

      if (
        validationSchema[name].validator != null &&
        typeof validationSchema[name].validator === "object"
      ) {
        if (value && !validationSchema[name].validator.regEx.test(value)) {
          error = validationSchema[name].validator.error;
        }
      }

      setState((prevState) => ({
        ...prevState,
        [name]: { value, error },
      }));
    },
    [validationSchema]
  );

  const handleOnSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (!validateState()) {
        callback(state);
      }
    },
    [state]
  );

  return {
    state,
    disable,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
    handleOnClear,
    setState,
  };
}
